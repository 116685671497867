<template>
  <v-container fluid class="pa-0">
    <v-card
      :class="`mx-auto mb-8 mt-2`"
      :width="contentWidth"
      outlined
      max-height="82vh"
    >
      <v-card-title style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)">
        <span class="my-auto"> {{ pageTitle }}</span>
        <download-csv
          :data="prepareDataForExport"
          :labels="labels"
          :fields="reportsLogFields"
          :primary-name="pageTitle"
          v-if="$vuetify.breakpoint.lgAndUp"
        >
          <v-btn
            text
            class="text-none text-body-2 font-weight-bold ml-4"
            color="accent"
            :disabled="loading || !reports.length"
          >
            <svg
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
              class="mr-2"
            >
              <path
                fill="currentColor"
                d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z"
              />
            </svg>
            Baixar CSV
          </v-btn>
        </download-csv>
        <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
        <v-tooltip v-if="showDataTable || !isResellerPage" bottom>
          <template v-slot:activator="{ on: tooltipOn }">
            <v-btn
              icon
              class="mr-2"
              color="secondary"
              v-on="tooltipOn"
              @click.stop="() => $emit('reload:reports')"
              :loading="loading"
            >
              <v-icon v-text="'mdi-reload'" />
            </v-btn>
          </template>
          <span>{{ $t("action.update") }}</span>
        </v-tooltip>
        <v-select
          v-if="showDataTable || !isResellerPage"
          class="mb-1 mr-4"
          :disabled="loading"
          v-model="period"
          :items="periodOptions"
          return-object
          dense
          outlined
          :multiple="false"
          style="max-width: 220px; height: 35px"
          @click.stop=""
        >
          <template v-slot:selection="{ item }">
            {{ $t(item.textKey) }}
          </template>
          <template v-slot:item="{ item }">
            {{ $t(item.textKey) }}
          </template>
          <template v-slot:prepend-inner>
            <v-icon v-text="'mdi-calendar-month'" class="mr-2" />
          </template>
        </v-select>
        <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
        <v-btn
          v-if="isResellerPage"
          @click="$emit('changeShowTable')"
          :class="`ml-auto my-auto`"
          icon
          large
          :loading="loading || !hasClientToken"
        >
          <v-icon
            v-text="showDataTable ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            size="28"
          />
        </v-btn>
      </v-card-title>
      <v-card-text v-if="showDataTable || !isResellerPage" class="mx-0 px-0">
        <v-data-table
          :items="reports"
          :headers="headers"
          :items-per-page="itemsPerPage"
          @pagination="pagination"
          :page.sync="currentPage"
          :loading="loading"
          :footer-props="{
            'items-per-page-options': itemsOptions,
          }"
          :single-expand="false"
          :height="showDataTable || !isResellerPage ? tableHeight : ''"
          fixed-header
          show-expand
          item-key="log_changed_date"
          item
        >
          <!-- AUTHOR HEADER-->
          <template v-slot:[`header.author`]="{ header }">
            {{ header.text }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  v-text="'mdi-help-circle'"
                  small
                />
              </template>
              <span class="font-weight-medium">
                O usuário que executou a ação
              </span>
            </v-tooltip>
          </template>

          <!-- CREATED AT -->
          <template v-slot:[`item.created_at`]="{ item }">
            {{ formatDateAndHour(item.created_at, applyUtc) }}
          </template>

          <template v-slot:[`item.context`]="{ item }">
            <Event :context="item.context" />
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <Action :log="item" />
          </template>

          <template v-slot:[`item.author`]="{ item }">
            <Author :author="item.author || {}" />
          </template>

          <template v-slot:[`item.description`]="{ item }">
            <Description :log="item" />
          </template>

          <!-- Salvo para Futuros logs com edição que tenha informação dos campos modificados.  -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pl-4 pt-4 pb-4 grey lighten-4">
              <div v-if="expandableContexts.includes(item.context)">
                <li v-if="!getObjectKeys(item.object.changes).length">
                  {{ "Dados não encontrados" }}
                </li>
                <li
                  class="py-1"
                  v-for="(key, i) in getObjectKeys(item.object.changes)"
                  :key="i"
                >
                  {{
                    editableCompanyTags[key]?.keyName ||
                    ddiTags[key]?.keyName ||
                    userEditableTags[key]?.keyName
                  }}
                  <span class="font-weight-medium">
                    " {{ item.object.changes[key].old_value }} "
                  </span>
                  alterado para
                  <span class="font-weight-medium"
                    >" {{ item.object.changes[key].new_value }} "</span
                  >
                </li>
              </div>
            </td>
          </template>

          <template
            v-slot:item.data-table-expand="{ item, isExpanded, expand }"
          >
            <v-icon
              v-if="expandableContexts.includes(item.context)"
              :class="[
                'v-data-table__expand-icon',
                { 'v-data-table__expand-icon--active': isExpanded },
              ]"
              @click.stop="expand(!isExpanded)"
              >$expand</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import {
  formatDateAndHour,
  getObjectKeys,
  subtractDays,
  subtractMonths,
} from "@/helpers/services/utils";
import { paging, tableHeight } from "@/helpers/variables/tablesDefault";
import {
  editableCompanyTags,
  userEditableTags,
  ddiTags,
} from "@/helpers/variables/tags";
import {
  getActionTranslation,
  getChangedTagValues,
} from "@/helpers/services/logs";
import {
  LOG_CODE,
  LOG_ACTION,
  LOG_CONTEXT,
  LOG_TRANSCRIPTION,
} from "@/helpers/variables/logs";
import { mapGetters } from "vuex";
import { expandableContexts } from "@/helpers/variables/logs";
import JsonCSV from "@/components/main/shared/download.vue";
import Author from "@/components/logs/cells/Author.vue";
import Description from "@/components/logs/cells/Description.vue";
import Action from "@/components/logs/cells/Action.vue";
import Event from "@/components/logs/cells/Event.vue";

export default {
  name: "ReportsHistory",

  components: {
    Author,
    Description,
    Action,
    Event,
    "download-csv": JsonCSV,
  },

  props: {
    pageTitle: { type: String, default: "Relatórios", required: true },
    reports: { type: Array, required: true, default: () => [] },
    loading: { type: Boolean, required: true, default: false },
    applyUtc: { type: Boolean, default: true },
    showDataTable: { type: Boolean, default: false },
    periodSelected: {
      type: Object,
      default: () => ({ textKey: "common.past7Days", value: subtractDays(7) }),
    },
  },

  data() {
    return {
      editableCompanyTags,
      ddiTags,
      userEditableTags,
      tableHeight,
      currentPage: 1,
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      pageHeight: 0,
      expanded: [],
      expandableContexts,
      reportsLogFields: [
        "created_at",
        "context",
        "author",
        "action",
        "description",
        "changes",
      ],
      labels: {
        created_at: "Data do registro",
        context: "Evento",
        author: "Ator",
        action: "Ação realizada",
        description: "Descrição",
        changes: "Alterações realizadas",
      },
      periodOptions: [
        { textKey: "common.past7Days", value: subtractDays(7) },
        { textKey: "common.past3Months", value: subtractMonths(3) },
        { textKey: "common.past6Months", value: subtractMonths(6) },
      ],
    };
  },
  computed: {
    ...mapGetters(["contentWidth", "company", "clientToken", "isResellerPage"]),

    hasClientToken() {
      return this.clientToken ? true : false;
    },

    headers() {
      return [
        {
          value: "created_at",
          text: "Data do registro",
          width: 180,
          sortable: false,
        },
        {
          value: "context",
          text: "Evento",
          width: 180,
          sortable: false,
        },
        {
          value: "action",
          text: "Ação",
          sortable: false,
          width: 200,
        },
        {
          value: "author",
          text: "Ator",
          sortable: false,
          width: 150,
        },
        {
          value: "description",
          text: "Descrição",
          sortable: false,
        },
      ];
    },
    prepareDataForExport() {
      return this.reports.map((log) => {
        let actor = log.author ? log.author.email : "-";
        let description = log.description || "";
        let action = log.object ? log.object.action : "-";
        let changes = "";
        const signaturesDescription = LOG_CODE[log.context].getDescription(log);

        if (
          log.context === LOG_CONTEXT.EDIT_TAGS ||
          log.context === LOG_CONTEXT.EDIT_USER_TAGS
        ) {
          changes = getChangedTagValues(log);
        }
        const translateAction = getActionTranslation(
          log.object?.status_code ||
            LOG_CODE[log.context].action ||
            log.object.action
        );
        return {
          created_at: this.formatDateAndHour(log.created_at),
          context: LOG_CONTEXT[log.context],
          action: LOG_ACTION[action] || translateAction,
          author: actor ? actor : "System",
          description: this.getTranscriptionValues(description)
            ? this.getTranscriptionValues(description)
            : this.removeBoldTags(signaturesDescription),
          changes: changes || "-",
        };
      });
    },

    period: {
      get() {
        return this.periodSelected;
      },
      set(value) {
        this.$emit("update:period-selected", value);
      },
    },
  },
  methods: {
    getObjectKeys,

    formatDateAndHour,

    getActionTranslation,

    getChangedTagValues,

    pagination(event) {
      this.currentPage = event.page;
    },

    handleResize() {
      this.pageHeight = window.innerHeight;
    },

    removeBoldTags(string) {
      const regex = /<\/?b>/gi;
      return string.replace(regex, "");
    },

    getTranscriptionValues(description) {
      const descriptionSplited = description.split(" ");
      let updatedDescription = "";

      for (let i = 0; i < descriptionSplited.length; i++) {
        let desc = descriptionSplited[i];
        if (LOG_TRANSCRIPTION[desc]) {
          updatedDescription += ` ${LOG_TRANSCRIPTION[desc]}`;
        } else if (LOG_CONTEXT[desc]) {
          updatedDescription += ` ${LOG_CONTEXT[desc]}`;
        } else if (LOG_ACTION[desc]) {
          updatedDescription += ` ${LOG_ACTION[desc]}`;
        } else {
          updatedDescription += ` ${desc}`;
        }
      }
      return updatedDescription.trim().toLowerCase();
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
