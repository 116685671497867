import {
  LOG_TRANSCRIPTION,
  LOG_ACTION,
  LOG_CODE,
} from "@/helpers/variables/logs";
import {
  editableCompanyTags,
  ddiTags,
  userEditableTags,
} from "@/helpers/variables/tags";

export function getUpdatedDescription(description) {
  let action = "";
  if (description.includes("CREATE")) {
    action = "CREATE";
  } else if (description.includes("DELETE")) {
    action = "DELETE";
  }

  return description.substring(description.indexOf(action), description.length);
}

export function translateDescription(log) {
  const { description } = log;
  let updatedDescription = getUpdatedDescription(description);
  const words = updatedDescription.split(" ");
  let translatedDescription = "";
  for (let i = 0; i < words.length; i++) {
    let word = words[i];
    if (!word) {
      word = "NOT_FOUND";
    }
    let translatedWord = getTranslation(word);
    translatedDescription += translatedWord;
  }
  return translatedDescription;
}

export function getTranslation(word) {
  return Object.keys(LOG_TRANSCRIPTION).includes(word)
    ? LOG_TRANSCRIPTION[word]
    : ` <span class="font-weight-medium">${word}</span>`;
}

export function getActionTranslation(action) {
  return LOG_ACTION[action];
}

export function getEmailLogsDescription(log) {
  let description = LOG_CODE[log.context].description;
  let IMPACTED_EMAILS = [];
  let AUTHOR = log.author.email || "Usuário não encontrado";
  log.impacted_emails.forEach((email) => {
    IMPACTED_EMAILS.push(`\n${email}`);
  });
  description = description.replace("{AUTHOR}", `<b>${AUTHOR}</b>`);
  description = description.replace(
    "{IMPACTED_USERS}",
    `<b>${
      IMPACTED_EMAILS.length ? IMPACTED_EMAILS : "usuários não encontrados"
    }</b>`
  );
  return description;
}

export function getNewUsersEmailLogsDescription(log) {
  let description = LOG_CODE[log.context].description;
  let IMPACTED_EMAILS = [];
  log.object.users_applied.forEach((email) => {
    IMPACTED_EMAILS.push(`\n${email}`);
  });
  description = description.replace(
    "{IMPACTED_USERS}",
    `<b>${
      IMPACTED_EMAILS.length ? IMPACTED_EMAILS : "usuários não encontrados"
    }</b>`
  );
  return description;
}

export function getUserLogDescription(log) {
  const { context, object, author } = log;
  let description = LOG_CODE[context].description;
  description = description.replace("{AUTHOR}", `<b>${author.name}</b>`);
  description = description.replace("{USER}", `<b>${object.user.email}</b>`);
  return description;
}

export function getOnboardingUserLogDescription(log) {
  const { context, object, author } = log;
  let description = LOG_CODE[context].description;
  description = description.replace("{AUTHOR}", `<b>${author.name}</b>`);
  description = description.replace(
    "{USER}",
    `<b>${object.user.primaryEmail}</b> pelo onboarding`
  );
  return description;
}

export function getVerifyPhoneLogDescription(log) {
  const { context, object, author } = log;
  let description = LOG_CODE[context].description;
  description = description.replace("{AUTHOR}", `<b>${author.name}</b>`);
  description = description.replace("{PHONE}", `<b>${object.phone}</b>`);
  return description;
}

export function getChangedTagValues(log) {
  const changes = [];
  const tagsChanged = log.object.changes ? Object.keys(log.object.changes) : "";
  if (!tagsChanged) return "Não encontrado";
  tagsChanged.forEach((key) => {
    const keyName =
      editableCompanyTags[key]?.keyName ||
      ddiTags[key]?.keyName ||
      userEditableTags[key]?.keyName;
    const newValue = log.object.changes[key].new_value;
    if (keyName && newValue) {
      changes.push(`${keyName}: ${newValue}`);
    }
  });

  return changes.join("\n");
}
